import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/core/Seo';
import Layout from '../layouts/Layout';
import InfoPage from '../templates/InfoPage';
import { breakpoint, color, pixels, radius, shadow, typography } from '../theme';

const BlogListing = styled.div`
  margin-bottom: 4rem;

  > div {
    margin-bottom: 3rem;

    :last-child {
      margin-bottom: 0;
    }

    @media ${breakpoint.md} {
      margin-bottom: 4rem;
    }
  }
`;

const Container = styled.div`
  position: relative;
  padding: 2rem;
  background-color: ${color.white};
  box-shadow: ${shadow.box.card};
  border-radius: ${radius.md};
  border-left: ${pixels.four} solid ${color.brand[60]};
`;

const Title = styled.h3`
  font-family: ${typography.family.title};
  font-size: ${typography.size.f4};
  margin-bottom: 0.5em;
  
  @media ${breakpoint.md} {
    font-size: ${typography.size.f3};
    margin-bottom: 0.25em;
  }
  a{
    text-decoration: none;
  }
  a:hover {
    @media (hover:hover) {
      text-decoration: underline;
    }
  }
`;
const Excerpt = styled.p`
  font-size: ${typography.size.f7};
  color: ${color.grey[70]};
  margin-bottom: 0.5em;
  
  @media ${breakpoint.md} {
    font-size: ${typography.size.f6};
  }
`;
const PostLink = styled.a`
  font-size: ${typography.size.f7};
  color: ${color.brand[60]};
  font-weight: bold;
  
  @media ${breakpoint.md} {
    font-size: ${typography.size.f6};
  }
`;

const Date = styled.p`
  position: absolute;
  top: -1em;
  right: 1em;
  background-color: ${color.brand[60]};
  padding: 0.25em 0.5em;
  border-radius: ${radius.md};
  font-weight: bold;
  font-size: ${typography.size.f8};
  color: ${color.white};
  margin-bottom: 0.25em;

  @media ${breakpoint.md} {
    font-size: ${typography.size.f7};
  }
`;

const Listing = ({ title, url, date, excerpt }) => {
  return (
    <Container>
      <Date>{date}</Date>
      <Title><a href={`${url}/`}>{title}</a></Title>
      <Excerpt>{excerpt}</Excerpt>
      <PostLink href={`${url}/`}>Continue Reading</PostLink>
    </Container>
  );
};

const Blog = ({ data }) => {

  return (
    <Layout>
      <SEO title="Blog" description="blog posts and articles about sports massage therapy" />
      <InfoPage span="Blog" title="All Posts">
        <BlogListing>
          {data.allMarkdownRemark.edges.map(edge => (
            <Listing
              key={edge.node.frontmatter.url}
              url={edge.node.frontmatter.url}
              title={edge.node.frontmatter.title}
              excerpt={edge.node.excerpt}
              date={edge.node.frontmatter.date}
            />
          ))}
        </BlogListing>
      </InfoPage>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query BlogListing {
    allMarkdownRemark(filter: {fileAbsolutePath: {glob: "**/src/content/posts/*"}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          excerpt(pruneLength: 150)
          frontmatter {
            title
            url
            date(formatString: "Do MMM YYYY")
          }
        }
      }
    }
  }
`;

